import { Component, Input, OnInit } from '@angular/core';
import { Room } from 'src/app/models/room.model';
import { RoomService } from 'src/app/services/room.service';
import { Router } from '@angular/router';


@Component({
    selector: 'booking',
    templateUrl: 'booking.component.html'
})
export class BookingComponent implements OnInit {   

    public rooms: Room[];
    public Arr = Array;

    constructor(public roomService: RoomService, public router: Router ){
        
    }

    ngOnInit(){
        this.rooms = this.roomService.getRooms();
    }

    

}
