<div class="container-fluid px-5 py-4">

    <div class="row rounded bg-white shadow">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 px-0">
            <gallery [room]="room"></gallery>
        </div>
        <div class="col pt-3 ml-3 mr-4 mb-3">
            <h2>{{room.label}}</h2>

            <p [innerHTML]="room.shortDescription + ' <br>Dimensioni: '+room.size+'mq².'">
            </p>



            <div class="row mt-4 mx-auto">
                <div class="col-auto">
                    <price-selector *ngIf="room.price" [prices]="room.price"></price-selector>
                </div>

            </div>
            <span style="font-size: .75rem;">*per i bambini fino a sei anni non si applica il sovrapprezzo</span>

            <h5 class="mt-4">Servizi</h5>
            <div class="row px-2">
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">
                    <span class="mr-1">
                        <i class="fas fa-wifi"></i>
                    </span>WiFi
                </div>

                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">
                    <span class="mr-1">
                        <i class="fas fa-desktop"></i>
                    </span>TV
                </div>

                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">
                    <span class="mr-1">
                        <i class="fas fa-fan"></i>
                    </span>Ventilatore
                </div>

                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">
                    <span class="mr-1">
                        <i class="fas fa-fire"></i>
                    </span>Angolo Cottura
                </div>

                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Asciugacapelli</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Biancheria</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Asciugamani</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Armadio</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Scrivania</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Bagno privato</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Balcone</div>
                <div class="alert alert-info col-md-12 col-lg-auto m-1 px-3 py-1">Parquet</div>
            </div>




        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-12 col-lg-4 ml-auto px-4 mt-2 bg-transparent"><button class="btn btn-md btn-secondary shadow w-100" [routerLink]="['/prenota']">
                Prenota subito </button></div>
        <div class="col-md-12 col-lg-2 mr-auto px-4 mt-2"><button class="btn btn-md btn-primary shadow w-100" (click)="location.back()"> Indietro </button>
        </div>
    </div>

</div>