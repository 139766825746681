<div class=" container p-3">
    <div class="row">

        <div class="col-md-12 col-lg mx-auto bg-transparent p-3 ">

            <div class="row px-3 pb-4">
                <div class="card col bg-transparent text-primary shadow-lg col px-0 text-center rounded">

                    <div class="card-header bg-primary text-white border-0 m-0 rounded-0 ">
                        <h1 class="font-weight-bold">Pretoria Suite</h1>

                    </div>

                    <div class="card-footer bg-white rounded-0 m-0">
                        <a class="text-secondary" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/B%26B+Pretoria+Suite">
                            <h5>Via Pretoria 63, Potenza, Italia</h5>
                        </a>
                    </div>
                </div>

            </div>

            <div class="row px-3">
                <div class="col-12 card rounded shadow mt-4">
                    <div class="row">
                        <div class="col-4 card-body bg-primary text-white text-center">
                            <span class="text-info" style="font-size:5rem">
                                <i class="fas fa-phone"></i>
                            </span>
                        </div>
                        <div class="col-8 card-body">
                            <h3>Telefono</h3>
                            <h4><a class="text-secondary"  href="tel:+393466857869">+39 346 685 7869</a></h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 card rounded shadow mt-4">
                    <div class="row">
                        <div class="col-4 card-body bg-primary text-white text-center">
                            <span class="text-warning" style="font-size:5rem">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                        <div class="col-8 card-body">
                            <h3>Indirizzo Mail</h3>
                            <h4><a class="text-secondary" href="mailto:talos91@libero.it">talos91@libero.it</a></h4>
                        </div>
                    </div>
                </div>
            </div>

            <!--div class="row">
                <div class="col-auto mx-auto">
                    <div class="p-4 mt-3 bg-primary text-white text-center rounded shadow border-white"
                        style="border: white 20px solid; max-width: max-content;">
                        <h3>

                            Telefono</h3>
                        <span class="text-info" style="font-size:5rem">
                            <i class="fas fa-phone"></i>
                        </span>

                        <h4><a class="text-white mx-4 d-flex" href="tel:+393466857869">+39 346 685 7869</a></h4>
                    </div>
                </div>

                <div class="col-auto mx-auto">
                    <div class="p-4 mt-3 bg-primary text-white text-center rounded shadow card border-white"
                        style="border: 20px solid;  max-width: max-content;">
                        <h3>

                            Mail</h3>
                        <span class="text-warning" style="font-size:5rem">
                            <i class="fas fa-envelope"></i>
                        </span>

                        <h4><a class="text-white d-flex px-4" style="min-width:100%" href="mailto:talos91@libero.it">talos91@libero.it</a></h4>

                    </div>
                </div>

            </div-->



        </div>

        <div  *ngIf="rooms[0].price" class="col-md-12 col-lg-4 bg-transparent p-3">
            <div class="shadow card rounded bg-light">
                <div class="card-header bg-primary text-white text-center p-0">
                    <h4 class="font-weight-bold my-4">La nostra offerta</h4>
                </div>
                <div class="card-body">
                    <ng-container *ngFor="let room of rooms">
                        <div class="row mb-4">
                            <div class="col-auto mx-auto">
                                <h5 class="text-center">
                                    Camera {{room.label}}
                                </h5>
                                <price-selector [prices]="room.price"></price-selector>
                            </div>
                        </div>
                    </ng-container>
                </div>


            </div>
        </div>
    </div>

</div>