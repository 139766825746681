import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RoomComponent } from './components/room/room.component';
import { BookingComponent } from './components/booking/booking.component';
import { StrutturaComponent } from './components/struttura/struttura.component';
import { PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'prenota', component: BookingComponent },
  { path: 'camere', component: StrutturaComponent },
  { path: "camere/:idRoom", component: RoomComponent },
  { path: '**', redirectTo: '' },

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
