    <div class="row">
        <div class="col-auto bg-white shadow rounded mx-auto">
            <h5 class="m-2">Ospiti</h5>
            <div class="d-flex pb-2">
                <ul ngbNav #nav="ngbNav" class="nav-pills">
                    <ng-container *ngFor="let option of prices; let i = index;">
                        <li ngbNavItem="{{i}}">
                            <a ngbNavLink>
                                <ng-container *ngFor="let n of  Arr(option.person).fill(1)">
                                    <i class="fa fa-male" style="font-size:1.5rem;margin-right:0.1rem"></i>
                                </ng-container>
                            </a>
                            <ng-template ngbNavContent>
                                <span style="font-size:1.5rem" class="font-weight-bold">{{option.price}}€</span>
                            </ng-template>
                        </li>
                    </ng-container>
                </ul>
            </div>

        </div>
        <div class="col-auto bg-primary rounded shadow text-white text-right mx-auto pl-5">

            <h5 class="mt-2">Prezzo</h5>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
