import { Component, OnInit } from '@angular/core';
import { Room } from 'src/app/models/room.model';
import { RoomService } from 'src/app/services/room.service';
import { Router } from '@angular/router';


@Component({
    selector: 'rooms',
    templateUrl: 'rooms.component.html'
})
export class RoomsComponent implements OnInit {

    public rooms: Room[];
    public previews: string[];
    public Arr = Array;

    constructor(public roomService: RoomService, public router: Router) {

    }

    ngOnInit() {
        this.rooms = this.roomService.getRooms();
        this.previews = this.rooms.map(room => { return "/assets/img/" + room.id + "/" + room.pics[0] + ".jpg" });
    }

    roomDetail(roomId) {
        this.router.navigate(['camere', roomId])
    }

}
