import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent {

  room = {
    id: "struttura", label: "La nostra struttura", pics: ["1", "2", "3", "4"], adults: 0, children: 0,
    shortDescription: "struttura",
    price: []
  };

  constructor(public router: Router) {

  }


}