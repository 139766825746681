
export class Room {
    id: string;
    label: string;
    pics: string[];
    adults: number;
    children: number;
    shortDescription: string;
    price?: RoomPrice[];
    size: number;
}


export class RoomPrice {
    price: number;
    person: number;
}