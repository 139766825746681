import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'struttura',
  templateUrl: './struttura.component.html',
})
export class StrutturaComponent {

  constructor() { }


}
