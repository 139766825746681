<nav class="navbar navbar-expand-lg navbar-dark bg-primary py-2 shadow">

  <a class="navbar-brand" [routerLink]="['']">


    <span class="font-weight-bold p-2 ">Pretoria Suite</span>
  </a>

  <span class="navbar-toggler border-0" type="button" (click)="toggleCollapse()">
    <span class="text-white bg-primary" style="font-size:1.75rem">
      <i class="fas fa-dot-circle"></i>
    </span>
  </span>

  <div [ngbCollapse]="collapse" class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">

      <li class="nav-item  active rounded px-2" [class.bg-white]="router.url.split('/')[1]=='camere'">
        <a class="nav-link" [ngClass]="router.url.split('/')[1]=='camere' && 'font-weight-bold text-primary'" [routerLink]="['/camere/suite']">La Suite</a>
      </li>

      <li class="nav-item  active rounded px-2" [class.bg-white]="router.url.split('/')[1]=='prenota'">
        <a class="nav-link" [ngClass]="router.url.split('/')[1]=='prenota' && 'font-weight-bold text-primary'" [routerLink]="['/prenota']">Prenota</a>
      </li>

    </ul>
  </div>

</nav>