<ngb-carousel #carousel [interval]="5000" [pauseOnHover]="true" (slide)="onSlide($event)">
  <ng-template ngbSlide *ngFor="let img of images; index as i">
    <!--div class="carousel-caption">
            <h3>My slide {{i + 1}} title</h3>
          </div-->
    <div class="gallery" class="h-100">
      <img [src]="img" class="w-100" alt="{{description}} - {{i}} - B&B Suite Pretoria - Via Pretoria 63, Potenza">
    </div>

  </ng-template>
</ngb-carousel>