import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: []
  })
  export class NavbarComponent{

    collapse: boolean = true;

    constructor(public router: Router){

    }

    navigateTo(url){
      this.router.navigate(url.split('/'));
    }

    toggleCollapse(){
      this.collapse = !this.collapse;
    }
  }