import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';

import { GalleryComponent } from './components/gallery/gallery.component';
import { RoomComponent } from './components/room/room.component';
import { RoomService } from './services/room.service';

import { CommonModule } from "@angular/common";
import { RoomsComponent } from './components/rooms/rooms.component';
import { PriceSelector } from './components/price-selector/price-selector.component';
import { BookingComponent } from './components/booking/booking.component';

import { StrutturaComponent } from './components/struttura/struttura.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    BookingComponent,
    GalleryComponent,
    RoomComponent,
    RoomsComponent,
    PriceSelector,
    StrutturaComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,


    NgbModule,


  ], exports: [
  ],
  providers: [
    RoomService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
