import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Room } from 'src/app/models/room.model';


@Component({
  selector: "gallery",
  templateUrl: 'gallery.component.html'
})
export class GalleryComponent implements OnInit {

  @Input() set room(room: Room) {
    if (!room) {
      this.images = []
    }
    this.description = room.label;
    this.images = room.pics.map(pic => { return "/assets/img/" + room.id + "/" + pic + ".jpg" });
  }

  images: string[];
  description: string;

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;


  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  ngOnInit() {
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
}