import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Room } from 'src/app/models/room.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from 'src/app/services/room.service';


@Component({
    selector: 'room',
    templateUrl: 'room.component.html'
})
export class RoomComponent {

    room: Room;

    constructor(public route: ActivatedRoute, public roomService: RoomService, public router: Router, public location: Location) {

    }


    ngOnInit() {
        this.route.paramMap.subscribe(params => this.room = this.roomService.getRoomById(params.get("idRoom")));
    }

}

