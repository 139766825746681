import { Inject, Injectable } from "@angular/core";
import { Room } from '../models/room.model';

@Injectable()
export class RoomService {

    public rooms: Room[] = [
        {
            id: "suite", label: "La Suite", pics: ["1", "2", "3", "4"], adults: 2, children: 1,
            shortDescription: "Spaziosa Suite con angolo cottura.<br>Su richiesta è disponibile un letto aggiuntivo.",
            //price:[{ person: 1, price: 65 }, { person: 2, price: 80 }], 
            size: 40
        },
    ]


    getRoomById(id: string) {
        return this.rooms.find(r => r.id == id);
    }

    getRooms() {
        return this.rooms;
    }
}