import { Component, Input } from '@angular/core';
import { RoomPrice } from 'src/app/models/room.model';

@Component({
    selector: 'price-selector',
    templateUrl: 'price-selector.component.html'
})
export class PriceSelector {
    
    @Input() prices: RoomPrice[];
    public Arr = Array;

}