
<div class="container card-deck">
    <div *ngFor="let room of rooms; let i = index;" class="card shadow">

        <div class="card-header p-0 bg-primary">
            <img [src]="previews[i]" class="card-img-top">

        </div>

        <div class="bg-primary text-white text-center p-2" style="font-size: .9rem;">
            <h5 class="font-weight-bold">
                {{room.label}}

            </h5>
            <span *ngIf="room.price && room.price[0]"> a partire da <span class="font-weight-bold">{{room.price[0].price}}€</span></span>

        </div>



        <div class="card-body text-secondary mx-2 d-flex">




            <p [innerHTML]="room.shortDescription" style="font-size:0.9">

            </p>

        </div>

        <div class="card-footer bg-white text-center border-0">
            <button (click)="roomDetail(room.id)" class="btn btn-sm btn-secondary w-100">
                <span>Scopri di più</span></button>
        </div>
    </div>

</div>