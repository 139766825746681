    <div class="row px-2 bg-light w-100 m-0">

        <div class="col-md-12 col-lg-6 bg-primary p-0 shadow text-white rounded mt-4 ml-auto">
            <gallery [room]="room"></gallery>

            <div class="text-center py-5"><span class="text-light"> Bed & Breakfast </span>
                <h2 class="font-weight-bold my-3"> Pretoria Suite </h2><span class="text-light"> Via Pretoria 63, Potenza </span>
            </div>

        </div>

        <div class="col-md-12 col-lg-4 mr-auto bg-white text-secondary p-0 text-center card rounded shadow mt-4">

            <div class="card-header border-0 bg-transparent">
                <div class="card-group ">
                    <div class="card bg-transparent border-0">
                        <div class="card-body text-center p-1 d-flex">
                            <button class="btn btn-primary w-100">
                                <label class="pt-3"><i class="fas fa-map-marked xl-icon"></i></label><br>Esplora la
                                Basilicata
                            </button>
                        </div>
                    </div>
                    <div class="card bg-transparent border-0">
                        <div class="card-body text-center p-1 d-flex">
                            <button class="btn btn-primary w-100" [routerLink]="['camere/suite']">
                                <label class="pt-3"><i class="fas fa-bed xl-icon"></i></label>
                                <br>

                                <span class="py-0">Dormi da noi</span>
                            </button>
                        </div>
                    </div>
                    <div class="card bg-transparent border-0">
                        <div class="card-body text-center p-1  d-flex">
                            <button class="btn btn-primary w-100">
                                <label class="pt-4"><i class="fas fa-utensils xl-icon"></i></label>
                                <br>

                                <span class="py-0">Mangia locale</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>


            <div class="card-body">
                <p class="p-5 d-flex"> Nato dalla passione per i viaggi di Maria Grazia e Claudio la Suite Pretoria offre un'ospitalità curata in ogni
                    dettaglio. A vostra disposizione avrete tutte le nostre attenzioni, una solida rete WiFi e una meticolosa cura per la pulizia, senza
                    dimenticare la colazione ai tavoli del caffè affacciato sulla piazza principale.<br><br> Nell'ampia Suite avrete a disposizione tavolo, angolo cottura,
                    un bagno spazioso e tutto l'occorrente per trascorrere uno splendido soggiorno.

                    <br><br> Offriamo una pulizia professionale, inoltre ci rivolgiamo a servizi di lavanderia
                    industriali per poter garantire la massima igiene ai nostri ospiti. </p>
            </div>

            <div class="card-footer text-center bg-transparent border-0 row">
                <div class="col-md-12 col-lg-6 mx-auto pb-3"><button class="btn btn-primary w-100" [routerLink]="['camera/suite']">Maggiori informazioni</button></div>
                <div class="col-md-12 col-lg-6 mx-auto pb-3"><button class="btn btn-secondary w-100" [routerLink]="['prenota']"> Riserva la suite </button></div>
            </div>

        </div>

    </div>

    <!--div class="mt-5 row">
        <div class="col-lg-12 col-xl-10 mx-auto">
            <rooms></rooms>
        </div>
    </div-->
