<body class="bg-primary" style="height: 100vh;">
    <navbar></navbar>

    <div class="bg-light container-fluid pt-3 pb-5">
        <router-outlet></router-outlet>
    </div>




    <div class="footer bg-primary text-light p-5 " style="font-size: .85rem;">
        <div class="row m-4 pb-4">
            <div class="col-xs-12 col-sm-12 col-md">
                <h6 class="font-weight-bold">Chi siamo</h6>
                <p>Pretoria Suite<br> Bed & Breakfast<br>
                <br>CIN IT076063C101445001</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md">
                <h6 class="font-weight-bold">Indirizzo</h6>
                <p> Via Pretoria, 63<br> 85100 Potenza<br> Italia</p>
            </div>
            <!--div class="col-xs-12 col-sm-12 col-md">
                <h6 class="font-weight-bold">Ragione Sociale</h6>
                <p>Sweet Pretoria S.A.S.<br> di Pastore Claudio<br>P.IVA 01884730761</p>
            </div-->
        </div>
        <div class="m-4 p-2 text-secondary"><span class="font-weight-bold">Disclaimer:</span> Questo sito utilizza
            cookies tecnici necessari per la navigazione tra pagine. <br> Non è attivo nessun servizio di profilazione commerciale e/o
            statistica. Teniamo ai vostri dati. </div>
    </div>
</body>